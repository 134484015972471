.sk_blog_container {
  font-family: "Rethink Sans", sans-serif;
}
/* .blog_main_container:nth-child(1) {
  width: 100%;
  flex-direction: row-reverse;
  display: flex;
  padding-top: 0px;
}
.blog_main_container:nth-child(1) .blog_main_container_clm {
  display: flex;
  flex-direction: row-reverse;
}
.blog_main_container:nth-child(1) .blog_main_container_clm_text {
  width: 50%;
}
.blog_main_container:nth-child(1) .blog_main_image_container {
  width: 50%;
}
.blog_main_container:nth-child(1) .blog_main_container_clm:nth-child(2) {
  padding: 0px 20px;
  width: 50%;
}
.blog_main_container:nth-child(1)
  .blog_main_container_clm:nth-child(2)
  .blog_clm_title {
  font-size: 2.25rem;
} */
.blog_main_container,
.blog_other_main_container {
  width: 33%;
}

@media screen and (max-width: 949px) {
  .blog_main_container:nth-child(1) {
    width: 50%;
    padding-top: 0px;
  }
  .blog_main_container:nth-child(1) .blog_main_container_clm {
    flex-direction: column;
  }
  .blog_main_container:nth-child(1) .blog_main_image_container,
  .blog_main_container:nth-child(1) .blog_main_container_clm:nth-child(2) {
    width: 100%;
    padding: 0px;
  }
  .blog_main_container:nth-child(1)
    .blog_main_container_clm:nth-child(2)
    .blog_clm_title {
    font-size: 1.5rem;
  }
  .blog_main_container,
  .blog_other_main_container {
    width: 50%;
    padding-top: 0px;
  }
}
@media screen and (max-width: 705px) {
  .blog_main_container,
  .blog_other_main_container {
    width: 100%;
  }
  .blog_main_container:nth-child(1) {
    width: 100%;
  }
}
