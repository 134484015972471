@import url("Header.css");
@import url("./Auth.css");
@import url("./Blog.css");
@import url("./room.css");
@import url("./Dashboard.css");
@import url("./room.css");

/* header */

.main_header_option {
  display: flex;
}
.burger_option {
  display: none;
}
@media screen and (max-width: 1000px) {
  .burger_option {
    display: flex;
  }
  .main_header_option {
    padding: 30px 0px;
    position: absolute;
    top: 110px;
    background-color: #f8f7f4;
    width: 100%;
    left: 0;
    right: 0;
    min-height: 100px;
    flex-direction: column;
    display: none;
  }
  .main_header_list {
    width: 70%;
    flex-direction: column;
    align-items: start;
  }
  .main_header_list div,
  .header_become_mentor,
  .header_auth_container {
    width: 100%;
    padding: 10px 0px;
    margin: 0px 0px;
    flex-direction: column;
  }
  .main_header_list_user {
    width: 70%;
  }
  .header_auth_container a {
    width: 100%;
    margin: 10px 0px;
    border-radius: 10px;
    text-align: center;
    border: 1.5px solid black;
  }
  .header_auth_container {
    width: 100%;
  }
  .header_auth_container div {
    width: 100%;
    padding: 10px 0px;
    margin: 0px 0px;
    border-radius: 10px;
  }
}

/* homebanner */

@media screen and (max-width: 850px) {
  .home_banner_container {
    flex-direction: column;
    align-items: center;
  }
  .home_banner_side {
    width: 100%;
  }
  .Homebanner-rt {
    display: none;
  }
  .home_banner_more_company {
    margin: 20px 0px 0px 0px;
  }
}

@media screen and (max-width: 600px) {
  .homebanner_para {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .home_banner_container {
    padding: 30px 30px;
  }
  .home_banner_para {
    font-size: 12px;
    text-align: justify;
  }
  .home_banner_title {
    font-size: 40px;
    /* text-align: center; */
  }
  .home_banner_button_option {
    flex-wrap: wrap;
  }
  .home_banner_actionbutton {
    margin: 10px 0px 0px 0px;
  }
}

/* pricingtable */
@media screen and (max-width: 920px) {
  .Pricingtable {
    flex-wrap: wrap;
    padding: 20px 10px;
    width: 100%;
  }
  .price_para {
    font-size: 25px;
    width: 100%;
  }
  .pricing_table_container {
    width: 100%;
  }
}

/* why_us */

@media screen and (max-width: 850px) {
  .w_u_heading {
    width: 70%;
  }
  .w_u_option_con {
    width: 100%;
    margin-bottom: 28px;
    padding: 30px 0px;
  }
  .w_u_container {
    padding: 20px 40px;
    flex-direction: column;
  }
}

/* assessment */

@media screen and (max-width: 850px) {
  .assess_clm_container {
    flex-direction: column;
    display: flex;
  }
  .assess_clm_container_row {
    flex-direction: column;
    width: 100%;
  }
  .assess_clm_container_row:nth-child(2){
    flex-direction: column-reverse;
  }
  .assess_clm_container_row_image,
  .assess_clm_container_row_text {
    width: 100%;
  }
  .assess_heading {
    width: 90%;
  }
  .assess_container {
    padding: 0px 30px;
  }
  .assess_siderow:nth-child(1) {
    width: 100%;
  }
  .assess_siderow:nth-child(2) {
    display: none;
  }
}

/* jobs showcase */
@media screen and (max-width: 850px) {
  .job-container {
    width: 100%;
    flex-direction: column;
    padding: 30px 20px;
  }
  .j-r-container {
    width: 90%;
  }
  .job-container-image {
    width: 100%;
    display: none;
    padding: 10px 0px;
  }
  .job-clm {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 0px 0px 0px;
  }
  .job-clm-row {
    width: 100%;
  }
  .job-clm-row:nth-child(2) {
    padding: 15px 0px;
  }
}

/* blog secition*/

.b-m-container {
  width: 30%;
}
@media screen and (max-width: 850px) {
  .b-m-container {
    width: 45%;
    margin: 20px 10px;
    /* margin-bottom: 10px; */
  }
  .b-m-container:nth-child(3) {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 640px) {
  .b-m-container {
    width: 100%;
  }
}

/* footer  */

@media screen and (max-width: 850px) {
  .footer-main {
    padding: 30px 0px;
  }
}
