@import url("https://fonts.googleapis.com/css2?family=Borel&family=Open+Sans:wght@400;600;700;800&display=swap");
@import url("./Home/Home.css");
@import url('https://fonts.googleapis.com/css2?family=Dhurjati&family=Open+Sans&family=Rethink+Sans&display=swap');

.loadingspinner {
  animation: 1s infinite loadingspinner;
}
@keyframes loadingspinner {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
