/* user DASHBOARD */

/* home */

.dashboard_burger {
  display: none;
}
@media screen and (max-width: 1050px) {
  .dashboard_burger {
    display: flex;
  }
  .dashboard_siderow {
    display: none;
    position: absolute;
    background-color: white;
    top: 60px;
    z-index: 100;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    height: 100%;
  }
}

.view_mentor_button {
  display: none;
}

.mentor_profile_mobile_view {
  width: auto;
}
.user_mentor_row img {
  display: none;
}
@media screen and (max-width: 1106px) {
  .user_mentor_row img {
    display: block;
  }
  .user_mentor_row {
    justify-content: space-between;
  }
  .user-feedback_clm:nth-child(1) {
    display: none;
  }
  .mentorProfile_view {
    display: none;
  }
  .user-feedback_clm {
    min-height: 0;
  }
  .user-feedback_clm {
    width: 100%;
  }
  .mentor_profile_row:nth-child(1) {
    width: 80px;
    height: 80px;
  }
  .mentor_profile_row {
    width: 100%;
    margin: 15px 0px;
  }
  .view_mentor_button {
    display: block;
  }
  .mentor_profile_mobile_view {
    justify-content: space-between;
    /* border: 1px solid black ; */
    border-radius: 20px;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 850px) {
  .dashboard_container {
    flex-wrap: wrap;
  }
  .dashboard_container_clm {
    width: 100%;
    margin-bottom: 30px;
  }
  .dashboard_profile_pic {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  .mentor_designation {
    width: 100%;
  }
  .mentor_profile {
    flex-direction: column;
    align-items: flex-start;
  }
  .mentor_designation_at {
    margin: 0px;
  }
  .user-feedback_clm {
    padding: 25px 20px;
  }
}

/* profile */
@media screen and (max-width: 1027px) {
  .user_profile_container {
    flex-direction: column;
  }
  .profile_siderow {
    width: 100%;
    height: auto;
    padding: 10px 20px;
  }
  .dashboard_container_clm {
    width: 100%;
  }
}

/* referal */

.refercontainer_siderow {
  width: 100%;
}

/* mentor */

/* .Celender */

.Celender,
.tCelender {
  max-width: 400px;
  min-height: 250px;
  margin: 10px;
  border-radius: 15px;
  padding: 20px 20px;
  background-color: white;

}
.celender-weeks div:hover {
  color: black;
  transition: 0.2s all ease-in;
}
.t-rt-d-ce {
  color: black;
}
