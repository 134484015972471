@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&family=Open+Sans&family=Work+Sans&family=Noto+Sans&family=Rubik&family=Comme:wght@500&family=Ubuntu&family=Ysabeau&family=Open+Sans:wght@400;600;700;800&family=Borel&family=Open+Sans:wght@400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comme:wght@500&family=Ubuntu&family=Ysabeau&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Borel&family=Open+Sans:wght@400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Parkinsans:wght@300..800&family=Rethink+Sans:ital,wght@0,400..800;1,400..800&family=Teko:wght@300..700&display=swap");
/*
  border: 1px solid;
 */
html {
  /* font-family: "Rethink Sans", sans-serif; */
  font-family: "Parkinsans", sans-serif;
}

.header_option_container {
  font-family: "Parkinsans", sans-serif;
  /* font-family: "Rethink Sans", sans-serif; */
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@keyframes scroll {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.animate {
  animation: scroll 15s linear infinite;
}
.productloading {
  animation: productloading 4s linear infinite;
}

@keyframes productloading {
  0% {
    background-color: rgba(0, 0, 0, 0.147);
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(0, 0, 0, 0.147);
  }
}

.loadingspinner {
  animation: 1s infinite loadingspinner;
}
@keyframes loadingspinner {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.blogheader::before {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 2px;
  left: 0;
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
}
.mentor-title {
  width: 100%;
}

.App {
  text-align: center;
}
.profile-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 25%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 10000;
}
.profile-container span {
  margin: 0px 7px;
  font-size: 17px;
  font-weight: 700;
}

.homeloading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.homeloading .loadingcontainer {
  position: relative;
  width: 350px;
  height: 350px;
  border: 30px solid transparent;
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 5px;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 5px;
  border-bottom: 20px solid #130b46;
  border-top: 20px solid #130b46;
  border-radius: 50%;
  animation: 1s rotation infinite 0s;
}
.homeloading span {
  position: absolute;
  font-weight: 700;
  font-size: 20px;
}

.troly {
  animation: troly 5s infinite;
}

.buy-button {
  position: relative;
  overflow-x: hidden;
}
.buybutton-animation {
  animation: buttonanimation 4s 5s linear;
}
@keyframes buttonanimation {
  0% {
    background-color: white;
    /* animation-duration: 7s; */
  }
  100% {
    border: 2px solid forestgreen;
    background-color: forestgreen !important;
  }
}
@keyframes troly {
  0% {
    width: 35px;
    transform: translateX(0px);
  }
  10% {
    width: 35px;
    transform: translateY(-10px);
  }
  15% {
    width: 35px;
    transform: translateY(0px);
  }
  100% {
    width: 35px;
    transform: translateX(280px);
  }
}

.wyolyus {
  min-height: 300px;
  padding: 30px 70px 60px 70px;
}
.wyolyustext {
  font-size: 35px;
  font-weight: 800;
  font-family: "Nunito";
  color: black;
}
.wyolyustext::after {
  content: "";
  position: absolute;
  width: 250px;
  height: 2px;
  background-color: black;
  transform: translate(-230px, 50px);
}
.wyolyuscontainer {
  width: 100%;
  padding: 40px 10px 40px 10px;
  display: flex;
  flex-wrap: wrap;

  align-items: flex-start;
  flex-direction: column;
}
.wyolyuscontainer .wyolucontainer-cm-row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.wyolyus-container-clm .material-symbols-outlined {
  color: black;
}
.wyolyus-container-clm {
  width: 47%;
  margin: 20px 10px 20px 10px;
  padding: 20px 20px;
  border-radius: 25px;
}
.wyolyus-container-clm span {
  color: rgb(19, 11, 70);
  font-size: 40px;
}
.wyolyus-text-title {
  padding: 10px 0px 5px 0px;
  font-size: 18px;
  font-family: "Nunito";
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9);
}
.wyolyus-text-para {
  font-size: 16px;
  font-family: "Nunito";
  text-align: justify;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
}
.wyolucontainer-cm-row:nth-child(2) {
  width: 100% !important;
}
.wyolyus-para {
  font-size: 16px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.602);
  font-family: "Roboto", sans-serif;
}

.Feedback {
  width: 100vw;
  min-height: 40vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 0px;
}
.Feedback .feedback-title {
  font-size: 23px;
  padding: 20px 0px;
}
.feedbackheader {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-evenly;
  border-bottom: 1px solid forestgreen;
}

.feedbackcontainer {
  width: 50%;
  padding: 15px 0px;
}
.feedbackcontainer .feed-titile {
  font-size: 20px;
  font-weight: 600;
}
.feedbackcontainer .rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
}

.ratingcontainer {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}
.ratingcontainer .rates {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ratingcontainer .rates input {
  width: 20px;
  height: 20px;
}

.roomtimer {
  position: absolute;
  top: 20px;
  left: 30px;
  background: black;
  z-index: 10000;
  padding: 5px 20px;
  border-radius: 20px;
}

.fwarning {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 40px;
  width: 250px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0px 3px 8px rgb(0, 0, 0, 0.4);
  border-left: 8px solid red;
  position: fixed;
  padding: 20px 15px;
  top: 120px;
  right: 0;
  z-index: 100000;
}
.warning-close {
  width: 30px !important;
  height: 30px !important;
  position: absolute;
}
.fwaringmsg {
  position: relative;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 15px;
  font-weight: 700;
}
.fwarning button {
  margin: 10px 0px;
  width: 130px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 15px;
  font-weight: 700;
  height: 40px;
  border: 2px solid forestgreen;
}

.Feedback button {
  width: 180px;
  height: 50px;
  margin: 20px 0px 10px 0px;
  border-radius: 30px;
  background-color: forestgreen;
  font-weight: 700;
  outline: none;
  color: white;
}

.waitinglist {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px 0px 30px 0px;
  min-height: 550px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(157, 253, 157, 0.4);
}

.waitinglistcontainer {
  width: 400px;
  min-height: 450px;
  padding: 15px 15px 30px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.waitinglistcontainer .waitinglist-title {
  font-size: 22px;
  font-weight: 700;
}
.aboutus {
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  padding: 40px 0px;
}
.aboutuscontainer {
  width: 80%;
  position: relative;
}
.aboutuscontainer .aboutus-title {
  font-size: 65px;
  position: relative;
  width: 25%;
  height: 75px;
  display: flex;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

/* .aboutuscontainer .aboutus-title div {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: red;
  border-radius: 50%;
} */
.aboutus-content-clm-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 50px 0px 60px 0px;
}
.aboutus-content-clm-row .au-content-clm-conteinar {
  /* width: 54%; */
  height: 250px;
  height: auto;
  display: flex;
  flex-direction: column;
  min-height: 100px;
}
.au-content-clm-conteinar span {
  font-size: 25px;
  text-align: justify;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.au-img {
  width: 45% !important;
  border-radius: 10px;
}

.au-readmore {
  width: 160px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid red;
  cursor: pointer;
  margin: 30px 0px 0px 0px;
}
.au-readmore button {
  font-weight: 600;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  /* color: ; */
}
.aboutus-content-clm-row:nth-child(2) {
  flex-direction: row-reverse;
}
.au-img {
  min-height: 200px;
  height: auto;
}
.aboutus-content-clm-row:nth-child(2) .au-content-clm-conteinar span {
  font-size: 20px;
  text-align: justify;
}
.aboutus-content-clm-row:nth-child(2) .au-content-clm-conteinar {
  height: auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}
.aboutus-content-clm-row:nth-child(2) .au-content-clm-conteinar ul li {
  margin: 5px 20px;
  list-style: decimal;
  font-size: 18px;
  /* font-weight: 600; */
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.au-final {
  font-size: 25px;
  text-align: center;
}

.contactus {
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 0px;
  /* background-color: rgb(131, 159, 241 , 0.694); */
}
.contactus-clm {
  width: 80%;
  padding: 30px 70px;
  background-color: #130b46;
  min-height: 400px;
  border-radius: 10px;
}
.contactus-clm span {
  font-size: 27px;
  color: rgba(255, 255, 255, 0.838);
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.contactus-clm-row {
  padding: 30px 25px;
  display: flex;
  min-height: 300px;
}
.contact-clm-row-container {
  width: 30%;
}
.c-c-clm {
  display: flex;
  min-height: 60px;
  cursor: pointer;
  width: 100%;
  padding: 10px 0px 10px 10px;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  margin: 10px 0px;
}
.ccclm-active {
  border: 2px solid #0973f9;
}
.cc-rightsider {
  width: 66%;
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-c-clm:hover {
  transition: 0.3s all cubic-bezier(0.25, 0.46, 0.45, 0.94);

  border: 2px solid #0973f9;
}
.c-c-clm span span {
  padding: 0px;
}
.c-c-clm span {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 20px;
  color: rgba(255, 255, 255, 0.738);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Loadingbar {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Loadingbar .Loading {
  width: 50px;
  height: 50px;
  border: 5px solid #0973f9;
  border-radius: 10px;
  animation: rotate 5s infinite;
  transform: translateX(0px) rotate(25deg);
}
.Loadingbar .downbar {
  width: 300px;
  height: 3px;
  background-color: #0973f9;
  margin: 5px 0px;
}
.loading-para {
  text-align: center;
  width: 100%;
  margin: 15px 0px;
}
.loading-para span {
  font-weight: 600;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

@keyframes rotate {
  0% {
    transform: translateX(0px) rotate(25deg);
  }
  50% {
    transform: translateX(280px) rotate(345deg);
  }
  100% {
    transform: translateX(0px) rotate(25deg);
  }
}

.mentorinfo {
  position: fixed;
  top: 25%;
  left: 40%;
  right: 50%;
  width: 300px;
  min-height: 350px;
  padding: 10px 30px;
  background-color: white;
  z-index: 10000;
  box-shadow: 0px 3px 8px rgb(0, 0, 0, 0.4);
  border-radius: 10px;
}

.mentorinfo-container {
  /* padding: 10px 30px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px 10px 10px;
  width: 100%;
  justify-content: center;
}

.mentor-infodetail .mentor-info-title {
  font-size: 17px;
  padding: 10px 0px 5px 0px;
  color: black;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.mentor-infodetail .mentor-info-content {
  font-size: 16px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: rgba(0, 0, 0, 0.801);
}
.mentorinfo-container p {
  padding: 10px 0px 5px 0px;
  font-size: 17px;
  color: black;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.mentorinfo-container span {
  font-size: 15px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: rgba(0, 0, 0, 0.623);
}
@media screen and (max-width: 1330px) {
  .au-img {
    display: none;
  }
  .aboutus-content-clm-row:nth-child(2) .au-img {
    display: none;
  }
  .aboutus-content-clm-row .au-content-clm-conteinar {
    width: 100%;
  }
  .au-img {
    display: none;
  }
  .aboutus-content-clm-row:nth-child(2) .au-img {
    display: none;
  }
  .aboutus-content-clm-row:nth-child(2) .au-content-clm-conteinar {
    width: 100%;
  }
  .aboutus-content-clm-row {
    padding: 30px 0px;
  }
  .aboutus-title {
    font-size: 50px !important;
    width: auto !important;
    max-width: 100%;
    /* height: 38px !important; */
  }
  .aboutus-title div {
    display: none;
  }
  .aboutus-content-clm-row .au-content-clm-conteinar {
    height: auto;
    display: flex;
    flex-direction: column;
    min-height: 100px;
  }
  .aboutus-content-clm-row .au-content-clm-conteinar span {
    margin-top: 15px;
  }
  .au-readmore {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .contactus-clm {
    padding: 30px 20px;
  }
}
@media screen and (max-width: 900px) {
  .logincontainer-right {
    display: none;
  }
  .logincontainer-left {
    width: 100%;
  }
  .b-s-container {
    flex-direction: column;
  }
  .blogcontainers:nth-child(1) {
    width: 100% !important;
  }
  .blogcontainers:nth-child(2) {
    display: none;
  }
}
@media screen and (max-width: 804px) {
  .Feedback {
    width: 100%;
  }
  .feedbackheader {
    width: 90%;
  }
  .feedbackcontainer {
    width: 90%;
  }
  .contact-clm-row-container {
    width: 100%;
    display: flex;
    flex-direction: column-reverse !important;
  }
  .cc-rightsider {
    width: 100%;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    margin-top: 20px;
  }
  .contactus-clm-row {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .ratingquality {
    display: none;
  }
  .ratingcontainer {
    width: 90%;
    padding: 10px 10px;
  }
  .aboutuscontainer {
    width: 90%;
  }
  .aboutus-title {
    font-size: 30px !important;
    width: 43% !important;
    height: 38px !important;
  }
  .aboutus-title div {
    width: 15px !important;
    height: 15px !important;
    display: flex;
  }
  .aboutus-content-clm-row {
    padding: 30px 0px;
  }
  .au-content-clm-conteinar span {
    font-size: 20px;
    text-align: justify;
    width: 100%;
  }
  .aboutus-content-clm-row .au-content-clm-conteinar {
    width: 100%;
  }
  .au-img {
    display: none;
  }
  .aboutus-content-clm-row:nth-child(2) .au-img {
    display: none;
  }
  .aboutus-content-clm-row:nth-child(2) .au-content-clm-conteinar {
    width: 100%;
  }
  .aboutus-content-clm-row:nth-child(2) .au-content-clm-conteinar ul li {
    margin: 15px 20px;
    /* padding: ;  */
    list-style: decimal;
    font-size: 20px;
    /* font-weight: 600; */
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  }
  .au-final {
    text-align: left;
    font-size: 20px;
  }
  .contactus-clm {
    width: 90%;
    padding: 30px 20px;
  }
  .contactus-clm span {
    font-size: 25px;
  }
  .contactus-clm-row {
    flex-direction: column;
  }
  .contact-clm-row-container {
    width: 100%;
    display: flex;
    flex-direction: column-reverse !important;
  }
  .cc-rightsider {
    width: 100%;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 840px) {
  .wyolyus-container-clm {
    width: 42%;
  }

  .wyolyuscontainer {
    padding: 40px 0px;
  }
}
@media screen and (max-width: 690px) {
  .wyolyus-container-clm {
    margin: 0px 10px 20px 10px;
  }
  .wyolyuscontainer .wyolucontainer-cm-row {
    justify-content: center;
  }
  .wyolucontainer-cm-row:nth-child(2) .wyolyus-container-clm:last-child {
    width: 80%;
    margin: 10px 10px;
  }
  .wyolyuscontainer {
    padding: 20px 0px;
  }
  .wyolyustext {
    padding: 0px 20px;
  }
  .wyolyus {
    padding: 40px 30px;
  }
  .wyolyus-container-clm {
    width: 40%;
    margin: 30px 10px;
  }
  .wyolyustext::after {
    display: none;
    transform: translate(-0px, 50px);
  }
  .wyolyustext {
    font-size: 24px;
    border-bottom: 4px solid #3399ff;
    padding: 0px 0px 10px 0px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 575px) {
  .j-r-container {
    flex-direction: column;
    width: 100% !important;
    margin: 10px 0px;
  }
}

@media screen and (max-width: 504px) {
  .blogheader {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .blog-header-right {
    padding: 10px 0px 5px 0px;
  }
  .blogcontainers:nth-child(1) {
    padding: 20px 7px;
  }
  .googleauthcontainer,
  .formerror,
  .signupheader,
  .signuppara {
    width: 90vw !important;
  }
}
@media screen and (max-width: 420px) {
  .footer-social {
    margin: 10px 0px;
  }
  .footer-optioncontainer {
    margin: 10px 5px;
  }
  .wyolyustext {
    font-size: 24px;
  }
  .wyolyuscontainer {
    padding: 20px 0px;
  }
  .wyolyus-container-clm {
    width: 90%;
  }
}
