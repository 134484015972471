.room {
  width: 100%;
  height: 100vh;
  background-color: rgb(21, 20, 20);
}
.user1 {
  /* position: relative;
    bottom: 90px;
    right: 50px;
    width: 300px;
    height: 180px; */
  border-radius: 10px;
  z-index: 1000;
  border: 1px solid rgba(255, 255, 255, 0.339);
}
.user1 video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.video-container {
  width: 100%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user2 {
  width: 70%;
  height: 90%;
  border-radius: 10px;
  position: relative;
}
.user2 video {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.controler {
  padding: 0px 40px;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(25, 24, 24);
  position: fixed;
  bottom: 0;
}
.r-problem {
  color: black;
  background-color: white;
  padding: 10px 15px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.reportaproblemcontainer {
  width: 450px;
  height: 250px;
  background-color: black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}
.reportp-title {
  color: white;
  padding: 20px 0px 10px 0px;
  width: 85%;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.r-problem-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 25%;
  width: 85%;
}
.r-problem-btn div:nth-child(1) {
  color: white;
  margin: 0px 20px 0px 0px;
  font-weight: 800;
  font-size: 13px;
}
.r-problem-btn div:nth-child(2) {
  width: 120px;
  height: 65%;
  background-color: white;
  border-radius: 20px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 13px;
  text-transform: capitalize;
}
.videouploader {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.02);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videouploader .videouploadercontainer {
  width: 350px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
  color: black;
  border-radius: 10px;
  padding: 15px 0px;
}
.videouploadercontainer span {
  font-weight: 700;
  width: 80%;
  font-size: 14px;
}
.videouploadercontainer p {
  width: 80%;
  font-size: 14px;
}
.progressingbar {
  width: 80%;
  height: 10px;
  margin: 10px 0px;
  background-color: white;
  border: 1px solid;
  border-radius: 30px;
}
.reportaproblemcontainer textarea {
  width: 85%;
  height: 55%;
  resize: none;
  background-color: rgb(21, 21, 21);
  margin: 0px 30px;
  padding: 8px 5px;
  border-radius: 15px;
  color: white;
}
.reportaproblem {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.controler .controler-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user1 .roomlogo {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.368);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user2 .roomlogo {
  position: absolute;
  /* top: -40px; */
  top: 10px;
  left: 10px;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.368);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user2 .roomlogo img,
.user1 .roomlogo img {
  width: 70%;
  height: 70%;
}
.nopermission {
  width: 100%;
  min-height: 50vh;
  padding: 40px 0px;
  display: flex;
  justify-content: center;
}
.nopermission video {
  width: 600px;
  height: 410px;
  background-color: black;
  border-radius: 10px;
  object-fit: cover;
}
.nopermissionvideocontainer {
  width: 37%;
  padding: 0px 20px;
}
.nopermissionvideocontainer .Room-title {
  font-size: 24px;
  font-weight: 600;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.room-content {
  padding: 10px 0px;
  font-size: 20px;
  font-weight: 500;
  color: rgb(40, 44, 52);
}
.room-content .roompart {
  display: flex;
  align-items: center;
  padding: 5px 0px;
}
.room-content .roompart .roompartuser {
  display: flex;
  align-items: center;
  margin-right: 40px;
}
.room-content .roompart .roompartuserdetail {
  display: flex;
  text-transform: capitalize;
  flex-direction: column;
  padding: 0px 10px;
}
.room-content .roompart .roompartuserdetail span {
  font-size: 14px;
}
.previewroom-button {
  padding: 20px 0px 10px 0px;
}
.previewroom-button button {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.previewroom-button button:nth-child(1) {
  background-color: royalblue;
  height: 60px;
  width: 180px;
  font-weight: 700;
  color: white;
}
.previewroom-button button:nth-child(2) {
  background-color: white;
  height: 60px;
  width: 180px;
  font-weight: 700;
  color: royalblue;
  margin: 0px 25px;
}
.roomcontrols {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  margin: 0px 15px;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  /* background-color: rgb(255, 255, 255, 0.17); */
  border: 1px solid rgb(255, 255, 255, 0.4);
  color: white;
}
.callend {
  background-color: red;
  width: 60px;
  border-radius: 30px;
  border: none;
}
.join-room {
  width: 400px;
  border-radius: 10px;
  border: 2px solid royalblue;
  height: 60px;
  font-weight: 700;
  color: royalblue;
  margin: 20px 0px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.chatroom {
  width: 350px;
  height: 400px;
  background-color: white;
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 10000;
  border-radius: 10px;
}
.chat-room-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  font-weight: 500;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.chatroom .chatmessages {
  height: 75%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin: 0px 15px; */
  overflow-y: scroll;
  border-radius: 10px;
  background-color: white;
  padding: 0px 0px 15px 0px;
}
/* .chatmessages span{
    margin: 5px 15px;
} */
.messagesender form {
  /* position: fixed; */
  display: flex;
  align-items: center;
  height: 50px;
  width: 350px;
  border: 1px solid;
  border-radius: 10px;
  bottom: 0;
}
.messagesender form input {
  background-color: transparent;
  /* border-top:1px solid; */
  width: 85%;
  height: 50px;
  padding: 0px 15px;
  border-bottom-left-radius: 10px;
  color: black;
  outline: none;
}
.messagesender form button {
  width: 15%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videomic {
  position: absolute;
  top: 10px;
  right: 20px;
  color: white;
  width: 50px;
  height: 50px;
  background-color: royalblue;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.tim-leftforroom {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}
.tim-leftforroom span {
  font-size: 40px;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.816);
}
.meetingstarted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}
.meetingstarted .meetingjoingbutton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
}
.meetingstarted .meetingjoingbutton button {
  display: flex;
  align-items: center;
  width: 190px;
  height: 60px;
  justify-content: center;
  border-radius: 6px;
}
.meetingjoingbutton button:nth-child(1) {
  background-color: #ffa500;
  color: black;
}
.meetingstarted .meetingjoingbutton button span {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 700;
  color: black;
}
.meetingjoingbutton button:nth-child(2) {
  background-color: #cfcfcf;
  color: black;
  margin-left: 10px;
}
.message {
  display: flex;
  /* flex-direction: column; */
  margin: 0px 15px;
  align-items: center;
  padding: 0px 10px;
  border-radius: 10px;
}
.messagebox-text {
  background-color: rgb(222, 222, 222);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
}
.messagebox-text span {
  font-size: 14px;
  color: black;
}
.m-t-cont p {
  margin: 0px 20px;
  font-size: 12px;
  font-weight: 600;
}
.chatbutton {
  max-width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  margin: 0px 10px;
  border-radius: 30px;
  background-color: rgba(209, 209, 209, 0.63);
  padding: 0px 3px;
}
.chatbutton input {
  width: 85%;
  height: 90%;
  border-radius: 30px;
  color: black;
  padding: 0px;
  outline: none;
  padding: 0px 10px;
  background-color: transparent;
  /* border: 1px solid forestgreen; */
}
.chatbutton button {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 850px) {
  .user2 {
    width: 90%;
    height: 45% !important;
  }
  .user1 {
    position: relative !important;
    margin: 10px 0px !important;
    width: 90% !important;
    height: 45% !important;
    right: 0 !important;
    bottom: 0 !important;
  }
  .video-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 410px) {
  .user2 {
    width: 90%;
    height: 50%;
  }
  .chatroom {
    left: 20px;
    right: 0px;
  }
  .meetingstarted span {
    font-size: 25px !important;
    text-align: center !important;
  }
  .meetingstarted .meetingjoingbutton button {
    width: 150px;
  }
  .meetingstarted .meetingjoingbutton button span {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 495px) {
  .reportaproblemcontainer {
    width: 90vw;
    height: 300px;
  }
}

@media screen and (max-width: 590px) {
  .r-problem span {
    display: none;
  }
  .r-problem {
    justify-content: center;
  }
  .icons-r-p {
    margin: 0px !important;
  }
}
