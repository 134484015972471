.icon-bar {
  width: 22px;
  height: 3px;
  background-color: #c59b6d;
  display: block;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin-top: 4px;
}

.switch input {
  display: none;
}
.switch {
  display: inline-block;
  width: 60px;
  height: 30px;

  position: relative;
}

.slider {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px;
  background: #b7b7b7;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: visible;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  width: 22px;
  height: 22px;
  background: white;
  border-radius: 100px;
  top: -0px;
  -webkit-transform: translateX(-0px);
  transform: translateX(0px);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23);
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(-5px);
  transform: translateX(30px);
  background: black;
}
.off {
  position: absolute;
  left: -25px;
  top: -5px;
  color: #706f6f;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.on {
  position: absolute;
  right: -20px;
  top: -5px;
  color: #d3d3d3;
  font-family: "roboto_light", sans-serif;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.switch input:checked ~ .off {
  color: #d3d3d3;

  top: -5px;
}

.switch input:checked ~ .on {
  color: #43ad50;
}

@media screen and (max-width: 1050px) {
  .login_form_container {
    width: 100%;
  }
  .student_on_boarding{
    width: 100%;
  }
  .student_on_boarding:nth-child(2){
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .login_container {
    flex-wrap: wrap;
  }
  .login_form_container:nth-child(1) {
    padding-bottom: 30px;
    padding: 20px 10px;
  }
  .login_form_container:nth-child(1) img {
    padding: 0px 20px;
  }
  .login_form_container:nth-child(2) {
    display: none;
  }
}

/* mentor form */

@media screen and (max-width: 950px) {
  .become_mentor_container {
    flex-wrap: wrap;
  }
  .become_mentor_container_row {
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .become_mentor_container_row img {
    padding: 20px 10px;
  }
  .become_mentor_container_row {
    padding: 10px 10px;
  }
  .become_mentor_container_row:nth-child(2) {
    padding: 10px 0px;
  }
}
